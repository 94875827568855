import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/LandingPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    document.title = "Studium trifft Segelfliegen"
    next()
})

export default router


import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class Home extends Vue {
    
    videosDialogOpened = false

    openVerein() {
        window.open('https://www.flugplatz-suemmern.de')
    }

    openVideosDialog() {
        this.videosDialogOpened = true
    }

    handleVideosDialogClose() {
        this.videosDialogOpened = false
    }

}
